import ReactGA from 'react-ga4';
// eslint-disable-next-line no-undef, @typescript-eslint/ban-ts-comment
// @ts-expect-error
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING, {
  titleCase: false,
});
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { exchangeOneTimeToken } from './api/actions/user/user-exchange-one-time-token';
import './App.css';
import { BackButtonManager } from './components/back-button/back-button';
import { HeaderContentManager } from './components/header-content/header-content';
import { PopupManager } from './components/popup/popup-manager';
import { AppConfig } from './config/app-config';
import { CurrentUserContextContainer } from './contexts/current-user-context';
import { isNotNullOrEmpty } from './helpers/common-helpers';
import { ClassesListPage } from './pages/classes/classes-list-page';
import { EmotionalSchemaManager } from './pages/curriculums/units/emotional-schema/emotional-schema';
import { UnitPage } from './pages/curriculums/units/unit-page';
import { UnitsListPage } from './pages/curriculums/units/units-list-page';
import { EmailConfirmationPage } from './pages/email/email-confirmation';
import { OnboardingConfirmEmailPage } from './pages/onboarding-confirm-email/onboarding-confirm-email';
import { GrantPermissionsPage } from './pages/permissions/grant-permissions-page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password';
import { ResetPasswordPage } from './pages/forgot-password/reset-password';
import { HomePage } from './pages/home/home-page';
import { InvitePage } from './pages/invite/invite-page';
import { PaymentSuccessPage } from './pages/payment-success/payment-success-page';
import { LoginPage } from './pages/login/login-page';
import { SignUpPage } from './pages/signup/signup-page';
import { UserConfigPage } from './pages/user-config/user-config-page';
import { AnalyticsPage } from './pages/user/analytics/analytics-page';
import { SavedSegmentsPage } from './pages/user/content/saved-segments-page';
import { BookmarksPage } from './pages/user/content/bookmarks-page';
import { JournalsPage } from './pages/user/content/journals-page';
import { ResponsePage } from './pages/user/content/response-page';
import { ResponseViewer } from './pages/user/content/components/response-viewer';
import { JournalEditor } from './pages/user/content/components/journal-editor';
import { OrganizationPage } from './pages/organization/organization-page';
import { AccountPage } from './pages/user/account/account-page';
import { ConceptsPage } from './pages/user/analytics/concepts-page';
import { GroupsStatisticsPage } from './pages/user/analytics/groups-statistics/groups-statistics-page';
import { GroupInvitePage } from './pages/user/invite/invite-page';
import { SendInvitesPage } from './pages/user/invite/send-invites-page';
import { SkillsPage } from './pages/user/analytics/skills-page';
import { SharePage } from './pages/share/share-page';
import { GroupSelectPage } from './pages/user/group-selection/group-select-page';
import { OrgSelectPage } from './pages/user/org-selection/org-select-page';
import { SegmentTile } from './pages/search/segment-tile';
import { GlobalSearchContextContainer } from './components/layouts/search-bar';
import { CurrentUserManager } from './components/user/current-user-manager';
import { PopUpSignin } from './components/popup/popup-signin';
import { PopupProvider } from './contexts/popup-context';
import { PreviousRoutesProvider } from './contexts/previous-routes-context';
import { UserProvider } from './contexts/user-context';
import { CurriculumsPage } from './pages/curriculums/curriculums-list-page';

const isAuthenticated = () => {
  return Cookies.get(AppConfig.tokenName) !== undefined;
};

const App: React.FC = () => {
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    // auto login when exists temporary token
    const url = new URL(window.location.href);
    const access_token = url.searchParams.get('at');
    if (isNotNullOrEmpty(access_token)) {
      Cookies.set(AppConfig.tokenName, access_token, {
        domain: new URL(location.href).hostname,
        expires: moment().add(60, 'seconds').toDate(),
      });

      exchangeOneTimeToken()
        .then((response) => {
          Cookies.set(AppConfig.tokenName, response.accessToken, {
            domain: new URL(location.href).hostname,
            expires: moment().add(100, 'days').toDate(),
          });
          location.href = url.pathname;
        })
        .catch(() => {
          location.href = url.pathname;
        });
    } else {
      setAllowed(true);
    }
  }, []);

  return !allowed ? null : (
    <GlobalSearchContextContainer>
      <UserProvider>
        <CurrentUserContextContainer>
          <CurrentUserManager>
            <BackButtonManager>
              <HeaderContentManager>
                <EmotionalSchemaManager>
                  <PopupProvider>
                    <Router>
                      <PreviousRoutesProvider>
                        <PopupManager>
                          <Route
                            exact
                            path="/analytics"
                            component={AnalyticsPage}
                          />

                          <Route
                            path="/curricula"
                            component={CurriculumsPage}
                          />

                          <Route exact path="/root" component={HomePage} />

                          <Route path="/invite" component={InvitePage} />
                          <Route
                            path="/payment-success"
                            component={PaymentSuccessPage}
                          />

                          <Route path="/login" component={LoginPage} />
                          <Route
                            path="/forgot-password"
                            component={ForgotPasswordPage}
                          />
                          <Route path="/reset" component={ResetPasswordPage} />

                          <Route
                            path="/email-confirmation"
                            component={EmailConfirmationPage}
                          />

                          <Route
                            path="/onboarding-confirm-email"
                            component={OnboardingConfirmEmailPage}
                          />

                          <Route
                            path="/grant-permissions"
                            component={GrantPermissionsPage}
                          />

                          <Route path="/signup" component={SignUpPage} />

                          <Route
                            exact
                            path="/account"
                            component={AccountPage}
                          />
                          <Route
                            exact
                            path="/analytics/skills"
                            component={SkillsPage}
                          />
                          <Route
                            exact
                            path="/analytics/concepts"
                            component={ConceptsPage}
                          />
                          <Route
                            exact
                            path="/analytics/groups-statistics/:uid?"
                            component={GroupsStatisticsPage}
                          />
                          <Route
                            exact
                            path="/saved"
                            component={SavedSegmentsPage}
                          />
                          {/* <Route exact path="/saved" component={SegmentTile} /> */}
                          <Route
                            exact
                            path="/journals/:uid"
                            component={JournalEditor}
                          />
                          <Route
                            exact
                            path="/org/:uid"
                            component={OrganizationPage}
                          />
                          <Route
                            exact
                            path="/journals"
                            component={JournalsPage}
                          />
                          <Route
                            exact
                            path="/responses/:uid"
                            component={ResponseViewer}
                          />
                          <Route
                            exact
                            path="/responses"
                            component={ResponsePage}
                          />

                          <Route
                            exact
                            path="/moments"
                            component={BookmarksPage}
                          />
                          <Route
                            exact
                            path="/invite-code"
                            component={GroupInvitePage}
                          />
                          <Route
                            exact
                            path="/send-invites"
                            component={SendInvitesPage}
                          />
                          <Route
                            path="/user-config"
                            component={UserConfigPage}
                          />
                          <Route
                            path="/group-select"
                            component={GroupSelectPage}
                          />
                          <Route path="/org-select" component={OrgSelectPage} />

                          <Route
                            path="/my-classes"
                            component={ClassesListPage}
                          />
                          <Route
                            exact
                            path="/curriculum"
                            component={UnitsListPage}
                          />
                          <Route
                            exact
                            path="/curriculum/:uid"
                            component={UnitsListPage}
                          />
                          <Route
                            exact
                            path="/curriculum/unit/:unitUid/:destination?"
                            component={UnitPage}
                          />
                          <Route
                            exact
                            path="/curriculum/:curriculumUid/unit/:unitUid/:destination?"
                            component={UnitPage}
                          />

                          <Route
                            exact
                            path="/share/:uid"
                            component={SharePage}
                          />
                          <Route exact path="/search" component={SegmentTile} />
                        </PopupManager>
                        <PopUpSignin />
                      </PreviousRoutesProvider>
                    </Router>
                  </PopupProvider>
                  <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    newestOnTop={true}
                    closeOnClick={true}
                    pauseOnHover={true}
                  />
                </EmotionalSchemaManager>
              </HeaderContentManager>
            </BackButtonManager>
          </CurrentUserManager>
        </CurrentUserContextContainer>
      </UserProvider>
    </GlobalSearchContextContainer>
  );
};

export default App;
