import Cookies from 'js-cookie';
import { AppConfig } from '../../config/app-config';
import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ComboBox } from '../combo-box/combo-box';
import { getUserGroupsPaged } from '../../api/actions/user-groups/user-groups-get-paged';
import { IListResult } from '../../models/list-result';
import { IUserGroup } from '../../models/user-group';
import { Button } from '../button/button';
import { IUser } from '../../models/user';
import { addUserToUserGroup } from '../../api/actions/user-groups/user-group-user-add';
import { toast } from 'react-toastify';
import { PopupContext } from '../../contexts/popup-context';
import { hasMotivation, hasPDMotivation } from '../../utils.js';
import useLocalStorage from '../../hooks/useLocalStorage';
import { TwPopup } from '../popup/tw-popup';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { resendConfirmationEmailByUserUid } from '../../api/actions/user/resend-confirmation-email';
import { logout } from '../../api/actions/logout-action';
import { useUser } from '../../contexts/user-context';

interface IProps {
  user: IUser | undefined;
  selectedOrg: string;
  parentComponent?: string;
  setSelectedOrg: Dispatch<SetStateAction<string>>;
  setSelectedOrgUid?: Dispatch<SetStateAction<string | undefined>>;
}

export const OrgFinder: React.FC<IProps> = ({
  user,
  selectedOrg,
  parentComponent,
  setSelectedOrg,
  setSelectedOrgUid,
}) => {
  const [orgs, setOrgs] = useState<
    IListResult<IUserGroup> | IUserGroup[] | null
  >(null);
  const [inputText, setInputText] = useState('');
  const [hasSeenPopup, setHasSeenPopup] = useLocalStorage(
    'orientation-popup',
    false,
    user?.uid
  );
  const history = useHistory();
  const { pathname } = useLocation();
  const { setUser } = useUser();
  const isOrgSelect = pathname === '/org-select';
  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { setOpenPopup } = popupContext;

  useEffect(() => {
    if (inputText.length === 0) setOrgs(null);
    if (inputText.length === 0) return;
    if (parentComponent !== 'create-class') {
      void (async () => {
        try {
          const res = await getUserGroupsPaged({
            page: 1,
            groupType: 'organization',
            searchText: inputText,
          });
          setOrgs(res);
        } catch (error) {
          console.error('Failed to fetch data form getUserGroupsPaged', error);
        }
      })();
    } else {
      setOrgs(filterAndSortGroups(user?.userGroups));
    }
  }, [inputText]);

  useEffect(() => {
    // @ts-ignore
    const selectedOrgObj = orgs?.data?.find((o) => o.title === selectedOrg);
    if (selectedOrgObj) {
      setSelectedOrgUid && setSelectedOrgUid(selectedOrgObj.uid);
      if (!handleDomainMatchCheck(selectedOrgObj)) {
        setOpenPopup('unmatched-domain');
      }

      if (
        handleDomainMatchCheck(selectedOrgObj) &&
        selectedOrgObj.emailConfirmationRequired === true &&
        !handleUserEmailConfirmed(user)
      ) {
        setOpenPopup('unconfirmed-email');
      }
    }
  }, [selectedOrg]);

  // @ts-ignore
  const selectedOrgToDisplay = orgs?.data?.find((o) => o.title === selectedOrg);

  const handleConfirm = async () => {
    // @ts-ignore
    const selectedOrgObj = orgs?.data?.find((o) => o.title === selectedOrg);
    if (!handleDomainMatchCheck(selectedOrgObj)) {
      setOpenPopup('unmatched-domain');
      return;
    }

    if (
      handleDomainMatchCheck(selectedOrgObj) &&
      selectedOrgObj.emailConfirmationRequired === true &&
      !handleUserEmailConfirmed(user)
    ) {
      setOpenPopup('unconfirmed-email');
      return;
    }

    try {
      if (selectedOrgToDisplay?.uid && user?.uid) {
        const res = await addUserToUserGroup(
          selectedOrgToDisplay.uid,
          user.uid
        );

        toast.success('School added successfully.');
        if (!hasSeenPopup) {
          setOpenPopup('signIn');
          setHasSeenPopup(true);
        }
        res.success && history.push('/analytics');
      }
    } catch (error) {
      toast.error('Unable to add school: ' + error);
    }
  };

  const convertToIListResult = (
    orgs: IListResult<IUserGroup> | IUserGroup[] | null
  ): IListResult<IUserGroup> | null => {
    if (Array.isArray(orgs)) {
      return {
        count: orgs.length,
        page: 1,
        pageSize: orgs.length,
        data: orgs,
      };
    }
    return orgs;
  };

  const filterAndSortGroups = (groups: IUserGroup[] | undefined) => {
    return (
      groups
        ?.filter(
          (group) =>
            group.groupType === 'organization' && group.enabled === true
        )
        .sort((a, b) => (a.title > b.title ? 1 : -1)) || null
    );
  };

  const handleDomainMatchCheck = (g: IUserGroup) => {
    if (!g.domain || g.domain.length < 3) return true; // allow use to join any group without set domain

    let domain: string | undefined;
    const match = user?.email.match(/@(.+)/);
    if (match) {
      domain = match[1];
    }
    return domain === g.domain;
  };

  const handleUserEmailConfirmed = (u: IUser | undefined) => {
    if (u === undefined) return;
    return u.email === u.confirmedEmail;
  };

  const handleResend = async () => {
    if (user && user.uid) {
      try {
        const res = await resendConfirmationEmailByUserUid(user.uid);
        res && toast.success('Confirmation email resent. Go to your inbox.');
      } catch (error) {
        console.error('Failed to resend confirmation email:', error);
        toast.error('Unable to resend confirmation email');
      }
    }
    setOpenPopup(null);
  };

  const logOut = async () => {
    try {
      await logout();
      localStorage.removeItem('user');
      setUser(null);
    } catch {
      /** */
    }

    Cookies.remove(AppConfig.tokenName);
    document.location.href = '/login';
  };

  return (
    <>
      {isOrgSelect && (
        <h2 className="text-black text-center mb-12 font-medium">
          Please select your School or Organization
        </h2>
      )}
      <ComboBox
        orgs={convertToIListResult(orgs)}
        selectedOrg={selectedOrg}
        setInputText={setInputText}
        setSelectedOrg={setSelectedOrg}
        style={isOrgSelect ? { border: '1px solid black' } : { border: '' }}
        showCreateNew={
          isOrgSelect && (hasMotivation(user?.motivation) || hasPDMotivation(user?.motivation)) ? true : false
        }
      />
      {isOrgSelect && (
        <>
          <div
            className={`${
              selectedOrgToDisplay ? '' : 'invisible'
            } bg-gray-200 p-8 mt-9 rounded`}
          >
            <div>{selectedOrgToDisplay?.title || ' '}</div>
            <div>{selectedOrgToDisplay?.address || ' '}</div>
          </div>
          <div
            className={`${selectedOrgToDisplay ? 'mt-10' : ''} ${
              selectedOrgToDisplay ? '' : 'invisible'
            }`}
          >
            <Button
              onClick={handleConfirm}
              className="rounded w-full text-white text-base font-light"
            >
              Confirm and Finish
            </Button>
          </div>

          <div
            className="mt-10 cursor-pointer"
            onClick={() => {
              if (!hasSeenPopup) {
                setOpenPopup('signIn');
                setHasSeenPopup(true);
                history.push('/analytics');
              } else {
                history.push('/analytics');
              }
            }}
          >
            skip
          </div>
        </>
      )}
      <TwPopup
        icon={<Logo />}
        popupName="unconfirmed-email"
        actionButton={
          <button
            type="button"
            className="border-0 cursor-pointer mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-2 bg-th-blue"
            onClick={handleResend}
          >
            Resend Confirmation
          </button>
        }
      >
        {`To join ${selectedOrg}, you must first confirm your email. Check your inbox or resend the confirmation email. After your email is confirmed you can try to join again.`}
      </TwPopup>
      <TwPopup
        icon={<Logo />}
        popupName="unmatched-domain"
        actionButton={
          <button
            type="button"
            className="border-0 cursor-pointer mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-2 bg-th-blue"
            onClick={logOut}
          >
            Logout
          </button>
        }
      >
        To join this organization use your organizational email to sign up.
      </TwPopup>
    </>
  );
};
